<template>
  <div>
    <v-list class="btn-add" flat nav v-if="roles('admin | regional')">
      <v-list-item-group :class="miniVariant ? 'pa-2' :  'pa-3'">
        <v-list-item class="btn-grad white--text rounded-lg" to="/campaigns/create">
          <v-list-item-avatar size="22" tile>
            <plus-icon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium">Nouvelle campagne</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-list class="sidebar" flat nav>

      <v-list-item-group :class="miniVariant ? 'pa-2' :  'pa-3'" color="primary">

        <v-list-item :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/">
          <v-list-item-avatar size="24" tile>
            <HomeIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="roles('admin | regional | supervisor')"
                     :ripple="false"
                     active-class="item--active font-weight-medium" to="/campaigns">
          <v-list-item-avatar size="24" tile>
            <CampaignIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Campagnes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="roles('admin | regional | supervisor')"
                     :ripple="false"
                     active-class="item--active font-weight-medium" to="/v2-campaigns">
          <v-list-item-avatar size="24" tile>
            <CampaignIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Campagnes V2</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-if="roles('admin')"
                     :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/products">
          <v-list-item-avatar size="24" tile>
            <ProductIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Produits</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="roles('admin')"
                     :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/gifts">
          <v-list-item-avatar size="24" tile>
            <BoxIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Cadeaux</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-if="roles('admin')"
                     :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/regionals">
          <v-list-item-avatar size="24" tile>
            <RegionalIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Utilisateurs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-if="roles('admin')"
                     :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/database">
          <v-list-item-avatar size="24" tile>
            <DatabaseIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Base de données</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="roles('admin')"
                     :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/cog">
          <v-list-item-avatar size="24" tile>
            <ConfigIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Configuration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false"
                     active-class="item--active font-weight-medium"
                     to="/account">
          <v-list-item-avatar size="24" tile>
            <AccountIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Mon compte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import CampaignIcon from "@/components/svg-icons/CampaignIcon.vue";
import HomeIcon from "@/components/svg-icons/HomeIcon.vue";
import ProductIcon from "@/components/svg-icons/ProductIcon.vue";
import RegionalIcon from "@/components/svg-icons/RegionalIcon.vue";
import ConfigIcon from "@/components/svg-icons/ConfigIcon.vue";
import AccountIcon from "@/components/svg-icons/AccountIcon.vue";
import BoxIcon from "@/components/svg-icons/BoxIcon.vue";
import DatabaseIcon from "@/components/svg-icons/DatabaseIcon.vue";
import PlusIcon from "vue-feather-icons/icons/PlusIcon";

export default {
  props: ['miniVariant'],
  components: {
    AccountIcon,
    ConfigIcon,
    RegionalIcon,
    ProductIcon,
    HomeIcon,
    CampaignIcon,
    BoxIcon,
    PlusIcon,
    DatabaseIcon
  }
}
</script>

<style scoped>

</style>