/***
 * Admin or Agency or Regional
 */
export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/dashboard/index.vue'),
        meta: {
            title: 'Tableau de board',
            roles: ['admin', 'regional', 'supervisor']
        }
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('../views/campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'regional', 'supervisor']
        }
    },
    {
        path: '/products',
        name: 'products',
        component: () => import('../views/products/index.vue'),
        meta: {
            title: 'Produits',
            roles: ['admin']
        }
    },
    {
        path: '/gifts',
        name: 'gifts',
        component: () => import('../views/gifts/index.vue'),
        meta: {
            title: 'Cadeaux',
            roles: ['admin']
        }
    },
    {
        path: '/database',
        name: 'database',
        component: () => import('../views/database/index.vue'),
        meta: {
            title: 'Base de donnée',
            roles: ['admin']
        }
    },
    {
        path: '/campaigns/create',
        name: 'campaigns.create',
        component: () => import('../views/campaigns/create/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'regional']
        }
    },
    {
        path: '/campaigns/:id/update',
        name: 'campaigns.update',
        component: () => import('../views/campaigns/create/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'regional']
        }
    },
    {
        path: '/regionals',
        name: 'regionals',
        component: () => import('../views/regionals/index.vue'),
        meta: {
            title: 'Régionaux',
            roles: ['admin']
        }
    },
    {
        path: '/regionals/supervisors',
        name: 'supervisors',
        component: () => import('../views/supervisors/index.vue'),
        meta: {
            title: 'Superviseurs',
            roles: ['admin']
        }
    },
    {
        path: '/regionals/animators',
        name: 'animators',
        component: () => import('../views/animators/index.vue'),
        meta: {
            title: 'Animateurs',
            roles: ['admin']
        }
    },
    {
        path: '/cog',
        name: 'cog',
        component: () => import('../views/setting.vue'),
        meta: {
            title: 'Config',
            roles: ['admin']
        }
    },


    {
        path: '/v2-campaigns',
        name: 'v2.campaigns',
        component: () => import('../views/v2_campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'regional', 'supervisor']
        }
    },
    {
        path: '/v2-campaigns/create',
        name: 'v2-campaigns.create',
        component: () => import('../views/v2_campaigns/create/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'regional']
        }
    },
    {
        path: '/v2-campaigns/:id/update',
        name: 'v2-campaigns.update',
        component: () => import('../views/v2_campaigns/create/index.vue'),
        meta: {
            title: 'Campagnes',
            roles: ['admin', 'regional']
        }
    },
]